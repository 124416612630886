import React from 'react';
import i18n, {parseLanguage, SUPPORTED_LANG} from "../../../translations/i18n";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import {DEFAUL_ERROR_MESSAGE, ERROR_CODES, OBJECTS_PATH, PROFESSIONAL_TYPES} from "../../../App";
import Modal from "react-modal";
// import Select from "react-select";
import {Helmet} from "react-helmet";
import LocalizationService from "../../../translations/configLangContext";

const PASS_REGEX = "^(?=.*[a-z|\u0430-\u044F|\u0451|\u03B1-\u03C9])(?=.*[A-Z|\u0410-\u042F|\u0401|\u0391-\u03A9])(?=.*[0-9])(?=.*[!@$%^&?*)(+=._-])(?=.{8,})";

const STEP_TYPE = {
    LOADING: 0,
    INSERT_EMAIL: 1,
    CHANGE_PASSWORD: 2,
    NOT_VALID_TOKEN: 3,
    EMAIL_SENT: 4,
    PASSWORD_CHANGED: 5
}

export default class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lan: "",
            timer: 5
        };
        this.step = STEP_TYPE.INSERT_EMAIL;
        this.userId = null;
        this.email = null;
        this.tokenCheckFlag = false;
        this.check = true;
        this.countdown = 0;
        this.subLanPlaceHolder = null;
        this.subLangOptions = [];
        this.subLanForced = null;
        this.professionalType = "";
        this.translateCompleted = false;
    }

    languageParsing() {
        this.subLanForced = new URL(window.location.href).searchParams.get('sublan');
        if(this.subLanForced) {
            // priority to 'subLanguage' param
            this.parsedLanguage = parseLanguage(this.subLanForced);
        }
        else if(this.props.forcedLanguage) {
            // if 'forcedLanguage' means that this domain must be shown to selected language only
            this.parsedLanguage = parseLanguage(this.props.forcedLanguage);
        }
        else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL
    }

    timerStarter() {
        if(this.countdown === 0) this.countdown = setInterval(()=>{this.setState({timer: this.state.timer -1})}, 1000);
    }

    async componentDidMount() {
        window.setNiceSelect();
        document.querySelectorAll("#sublanguages + .nice-select li.option").forEach(el => {
            el.addEventListener("click", event => {
                this.subLanguageEventHandler(event.target.getAttribute("data-value"))
            })
        })

        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, null, this.parsedLanguage.CULTURE_CODE, "forgot_password");

        this.setState({ translateCompleted: true});
    }

    componentDidUpdate(){
        if(this.state.timer === 0) {
            clearInterval(this.countdown);
            window.location.replace(this.props.redirectUrl);
        }
        window.setNiceSelect();
        document.querySelectorAll("#sublanguages + .nice-select li.option").forEach(el => {
            el.addEventListener("click", event => {
                this.subLanguageEventHandler(event.target.getAttribute("data-value"))
            })
        })
    }

    subLanguageMenuRender() {
        if(!this.props.subLanguagesInfo) return;
        this.props.subLanguagesInfo.forEach(subLanInfo => {
            let optObj = {};
            optObj.label = subLanInfo.label;
            optObj.key = subLanInfo.sub_culture;
            if(optObj.key === this.parsedLanguage.CULTURE_CODE) {
                this.subLanPlaceHolder = optObj.label;
            }
            this.subLangOptions.push(optObj);
        });
    }

    subLanguageDefaultMan() {
        let defaultSubLan;
        for(let subLanInfo of this.props.subLanguagesInfo) {
            if(subLanInfo.isDefaultSubLan) defaultSubLan = subLanInfo
        }
        if(this.parsedLanguage && defaultSubLan.sub_culture && this.parsedLanguage.CULTURE_CODE !== defaultSubLan.sub_culture) this.subLanguageEventHandler(defaultSubLan.sub_culture)
    }

    subLanguageEventHandler(subLanKey) {
        // to avoid all reload-related issues we use 'sublan' param workaround
        let redirectUrl = new URL(window.location)
        redirectUrl.searchParams.set('sublan', subLanKey);
        window.location = redirectUrl;
    }

    async noInternetConnectionCheck(error, url) {
        console.error(`ERROR: An error occurred while submitting: ${error}`);
        await fetch(this.props.dbSelectService, {
            // checking if connection is active by pinging 'this.props.dbSelectService' url
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify({reqType: "internetConnectionCheck"}),
            headers: new Headers({'Content-Type': 'application/json'})
        }).catch(pingError => {
            // Ping to 'this.props.dbSelectService' url went wrong, no internet connection detected
            console.error(`ERROR: Ping to URL ${this.props.dbSelectService} failed, no internet connection. Error message: ${pingError}`);
            this.errorText = !i18n.t('professional.errorMessages.no_internet_connection').includes('professional.errorMessages.no_internet_connection') ?
                i18n.t("professional.errorMessages.no_internet_connection") : DEFAUL_ERROR_MESSAGE.NO_INTERNET_CONNECTION;
            this.errorText += " (Error Code: " + ERROR_CODES.REGISTRATION.NO_INTERNET_CONNECTION + ")";
        });
        if (!this.errorText) {
            // Ping to 'this.props.dbSelectService' url went fine, maybe the backend crashed
            console.info(`Ping to ${this.props.dbSelectService} worked, maybe a crash occurred in ${url} function`);
            this.errorText = !i18n.t('professional.errorMessages.genericError').includes('professional.errorMessages.genericError') ?
                i18n.t("professional.errorMessages.genericError") : DEFAUL_ERROR_MESSAGE.GENERIC_ERROR;
            this.errorText += " (Error Code: " + ERROR_CODES.REGISTRATION.BACKEND_CRASH + ")";
        }
        this.setState({isErrorModalOpen: true});
        document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
    }

    i18nByProfType(key){
        return !i18n.t(`${key}_` + this.props.profType).includes(`${key}_`) ?
        i18n.t(`${key}_` + this.props.profType) :
        i18n.t(`${key}`)
    }

    mandatoryFieldsRender(text = "*", hiddenText = 'professional.campoObbligatorio') {
        return <span className="form-input-indicator"><span className="sr-only u-hidden">{this.i18nByProfType(hiddenText)} </span>{text}</span>;
    }

    renderInsertEmailStep(errors, touched, isSubmitting) {
        return (
            <Form>
                {this.props.subLanguagesInfo ?
                    <fieldset className="c-fieldset">
                        <div className="u-wrap-fields">
                            <div className="form-row">
                                <div className="form-group">
                                    <select id="sublanguages" name="sublanguages" className="c-input c-input-select"> {/* removed onChange={(event) => { this.subLanguageEventHandler(event.key) }} */}
                                        {this.subLanPlaceHolder === null && (
                                            // LOCALIZE:
                                            <option value="" selected>Choose your language</option>
                                        )}
                                        {this.subLangOptions.map((opt) => {
                                            if (opt.label === this.subLanPlaceHolder) return <option value={opt.key} selected>{opt.label}</option>
                                            else return <option value={opt.key}>{opt.label}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    : ""
                }

                <div className="o-headers">
                    <h1 className="h h-1">{i18n.t('forgotPassword.reset_your_password')}</h1>
                </div>
                <div className="o-prose">
                    <div className="e-content">
                        <p dangerouslySetInnerHTML={{__html: i18n.t('forgotPassword.info_text')}}></p>
                    </div>
                </div>

                <div className="validation-summary-valid">
                    <ul>
                        <li style={{display: 'none'}}></li>
                    </ul>
                </div>{/* /.validation-summary-valid */}

                <fieldset className="c-fieldset">
                    <div className="u-wrap-fields">
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="email" className="c-label">{i18n.t('forgotPassword.email_filed_label')}
                                    {this.mandatoryFieldsRender()}
                                </label>
                                <Field maxLength="254" name="email" type="text" className={'form-control c-input' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group form-group-buttons">
                                <div className="o-button o-button-full">
                                    <input type="submit" className="c-button c-button-primary txt-uppercase" disabled={isSubmitting}
                                        value={i18n.t('forgotPassword.submit_button')} />
                                </div>{/* /o-button */}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </Form>
        )
    }

    renderChangePasswordStep(errors, touched, isSubmitting) {
        return (
            <Form>
                <div className="o-headers">
                    <h1 className="h h-1">{i18n.t('forgotPassword.reset_your_password')}</h1>
                </div>

                <div className="validation-summary-valid">
                    <ul>
                        <li style={{display: 'none'}}></li>
                    </ul>
                </div>{/* /.validation-summary-valid */}

                <fieldset className="c-fieldset">
                    <div className="u-wrap-fields">
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="password" className="c-label">{i18n.t('forgotPassword.enter_password')}</label>
                                <Field maxLength="254" name="password" type="password" className={'form-control c-input' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="passwordConfirmation" className="c-label">{i18n.t('forgotPassword.confirm_password')}</label>
                                <Field maxLength="254" name="passwordConfirmation" type="password" className={'form-control c-input' + (errors.passwordConfirmation && touched.passwordConfirmation ? ' is-invalid' : '')} />
                                <ErrorMessage name="passwordConfirmation" component="div" className="invalid-feedback" />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group form-group-buttons">
                                <div className="o-button o-button-full">
                                    <input type="submit" className="c-button c-button-primary txt-uppercase" disabled={isSubmitting}
                                        value={i18n.t('forgotPassword.submit_button')} />
                                </div>{/* /o-button */}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </Form>
        )
    }

    renderNoTokenFound() {
        return (
            <div className="o-headers">
                <h1 className="h h-1">NOT VALID TOKEN</h1>
            </div>
        )
    }

    renderEmailSent() {
        return (<>
            <div className="o-headers">
                <h1 className="h h-1">{i18n.t('forgotPassword.pass_info_sent_title')}</h1>
            </div>
            <div className="o-prose">
                <div className="e-content">
                    <p dangerouslySetInnerHTML={{__html: i18n.t('forgotPassword.pass_info_sent_text')}}></p>
                </div>
            </div>
        </>)
    }

    renderPassChanged() {
        return (<>
            <div className="o-headers">
                <h1 className="h h-1">{i18n.t('forgotPassword.pass_success_title')}</h1>
            </div>
            <div className="o-prose">
                <div className="e-content">
                    <p dangerouslySetInnerHTML={{__html: i18n.t('forgotPassword.pass_success_text')}}></p>
                </div>
            </div>
            {this.props.redirectUrl ?
                <div className="o-prose">
                    <div className="e-content">
                        <p dangerouslySetInnerHTML={{
                            __html: i18n.t('professional.succesPageRedirect',
                                {time: `${this.state.timer}`, link: `<a class="link-break" href="${this.props.redirectUrl}">${this.props.redirectUrl}</a>`}
                            )
                        }}></p>
                    </div>
                </div>
                : ""
            }
        </>)
    }

    renderStepManager(errors, touched, isSubmitting) {
        //saving querystring
        this.queryStringParams = new URLSearchParams(this.props.location.search);
        if(this.isThereToken() && !this.tokenCheckFlag) {
            this.tokenCheck();
            this.step = STEP_TYPE.LOADING;
        }
        switch (this.step) {
            case STEP_TYPE.INSERT_EMAIL:  return this.renderInsertEmailStep(errors, touched, isSubmitting);
            case STEP_TYPE.CHANGE_PASSWORD: return this.renderChangePasswordStep(errors, touched, isSubmitting);
            case STEP_TYPE.NOT_VALID_TOKEN: return this.renderNoTokenFound();
            case STEP_TYPE.EMAIL_SENT: return this.renderEmailSent();
            case STEP_TYPE.PASSWORD_CHANGED: {
                if(this.props.redirectUrl) this.timerStarter();
                return this.renderPassChanged();
            }
            case STEP_TYPE.LOADING: {
                return (<>
                    <div className="o-headers">
                        <div class="h-img">
                            <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                        </div>
                    </div>
                </>);
            }
            default:
                break;
        }
    }

    tokenCheck() {
        fetch(this.props.serviceUrl, {
            credentials: 'same-origin',
            method: 'POST',
            // use token from querystring
            body: JSON.stringify({reqType: "tokenCheck", token: this.queryStringParams.get("token")}),
            headers: new Headers({ 'Content-Type': 'application/json' })
        })
            .then(response =>{
                if(response.status === 404) {
                    this.step = STEP_TYPE.NOT_VALID_TOKEN;
                    this.tokenCheckFlag = true;
                    this.forceUpdate();
                    return;
                } else if(response.status === 406) {
                    alert("EXPIRED TOKEN");
                }
                return response;
            })
            .then(res => {
                if(res) return res.json();
                else return null;
            })
            .then(res => {
                if(res) {
                    this.tokenCheckFlag = true;
                    this.step = STEP_TYPE.CHANGE_PASSWORD;
                    this.userId = res.userId;
                    this.email = res.email;
                    this.professionalType = res.professionalType
                    this.forceUpdate();
                }
            }).catch(async error => {
                await this.noInternetConnectionCheck(error, this.props.serviceUrl);
        });
    }

    isThereToken() {
        //pick token from querystring
        return this.queryStringParams.get("token");
    }

    footerLoc() {
        let localized = i18n.t('footer');
        let localizedP = this.professionalType != "" ? i18n.t('footer_'+this.professionalType) : false;
        if(localizedP && i18n.exists('footer_'+this.professionalType)) {
            document.getElementById('footerText').innerHTML = localizedP;
        } else if (localized && i18n.exists('footer')) {
            document.getElementById('footerText').innerHTML = localized;
        }
    }

    render() {
        this.languageParsing();
        if(this.props.subLanguagesInfo && !this.subLanForced) this.subLanguageDefaultMan();

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        this.subLanguageMenuRender();

        this.footerLoc();
        return (<>
            <Helmet>
                <title>{i18n.t('forgotPassword.tabTitle')}</title>
                <meta name="description" content={i18n.t('forgotPassword.tabTitle')}/>
            </Helmet>
            <Formik
                initialValues={{
                    email: "",
                    password: ""
                }}
                validationSchema={(this.step === STEP_TYPE.INSERT_EMAIL && Yup.object().shape({
                    email: Yup.string().email(i18n.t('professional.notValidEmail'))
                        .required(i18n.t('econsent.form.campoObbligatorio'))
                })) || (this.step === STEP_TYPE.CHANGE_PASSWORD && Yup.object().shape({
                    password: Yup.string()
                        .required(i18n.t('econsent.form.campoObbligatorio'))
                        .matches(PASS_REGEX, i18n.t('forgotPassword.pass_requirements')),
                    passwordConfirmation: Yup.string()
                        .required(i18n.t('econsent.form.campoObbligatorio'))
                        .oneOf([Yup.ref('password')], i18n.t('forgotPassword.no_match_pass'))
                }))}
                onSubmit={(values, { setSubmitting }) => {
                    if (this.step === STEP_TYPE.INSERT_EMAIL) {
                        values.reqType = "emailCheck";
                        values.reqLink = window.location.href;
                        fetch(this.props.serviceUrl, {
                            credentials: 'same-origin',
                            method: 'POST',
                            body: JSON.stringify(values),
                            headers: new Headers({ 'Content-Type': 'application/json' })
                        })
                            .then(response => {
                                let errorCodeIntro = !i18n.t('errorCodeIntro').includes('errorCodeIntro') ?
                                    i18n.t('errorCodeIntro') :
                                    "Error code";
                                if (response.status === 400) {
                                    this.errorText = i18n.t("forgotPassword.genericError");
                                    this.setState({ isErrorModalOpen: true });
                                    document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
                                    return response.error = true;
                                } else if (response.status === 401) {
                                    this.errorText = i18n.t("forgotPassword.email_not_found") + " (" + errorCodeIntro + ": " + ERROR_CODES.FORGOT_PASSWORD.NO_EMAIL_FOUND + ")";
                                    this.setState({ isErrorModalOpen: true });
                                    document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
                                    return null;
                                } else if (response.status === 402) {
                                    this.errorText = i18n.t("forgotPassword.email_not_found") + " (" + errorCodeIntro + ": " + ERROR_CODES.FORGOT_PASSWORD.USER_BB_NOT_REGISTERED + ")";
                                    this.setState({ isErrorModalOpen: true });
                                    document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
                                    return null;
                                } else if (response.status === 403) {
                                    this.errorText = i18n.t("forgotPassword.email_not_found") + " (" + errorCodeIntro + ": " + ERROR_CODES.FORGOT_PASSWORD.USER_VR + ")";
                                    this.setState({ isErrorModalOpen: true });
                                    document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
                                    return null;
                                } else if (response.status === 406) {
                                    this.errorText = i18n.t("forgotPassword.doi_not_active") + " (" + errorCodeIntro + ": " + ERROR_CODES.FORGOT_PASSWORD.USER_NOT_ACTIVE + ")";
                                    this.setState({ isErrorModalOpen: true });
                                    document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
                                    return null;
                                }
                                return response;
                            })
                            .then(res => {
                                if (!res) { return res }
                                return res.json();
                            })
                            .then(res => {
                                if (!res) {
                                    // an error occurred, cannot go to second step
                                    setSubmitting(false);
                                    return null;
                                }
                                this.step = STEP_TYPE.EMAIL_SENT;
                                this.forceUpdate();
                                setSubmitting(false);
                            }).catch(async error => {
                                await this.noInternetConnectionCheck(error, this.props.serviceUrl);
                                setSubmitting(false);
                        });
                    } else if (this.step === STEP_TYPE.CHANGE_PASSWORD) {
                        values.reqType = "passwordOverride";
                        values.userId = this.userId;
                        values.email = this.email;
                        fetch(this.props.serviceUrl, {
                            credentials: 'same-origin',
                            method: 'POST',
                            body: JSON.stringify(values),
                            headers: new Headers({ 'Content-Type': 'application/json' })
                        })
                            .then(response => {
                                if (response.status === 400) {
                                    alert(i18n.t("forgotPassword.genericError"));
                                }
                                return response;
                            })
                            .then(res => { return res; })
                            .then(res => {
                                this.step = STEP_TYPE.PASSWORD_CHANGED;
                                setSubmitting(false);
                            }).catch(async error => {
                                await this.noInternetConnectionCheck(error, this.props.serviceUrl);
                                setSubmitting(false);
                        });
                        setSubmitting(false);
                    }
                }} >

                {({ errors, status, touched, isSubmitting }) => (
                    this.renderStepManager(errors, touched, isSubmitting)
                )}
            </Formik>

            <Modal
                isOpen={this.state.isErrorModalOpen}
                aria={{ labelledby: "heading", describedby: "full_description" }}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                className="aid-dialog"
                overlayClassName="aid-dialog-overlay"
            >
                <div className="aid-dialog-container">
                    <div className="aid-dialog-close">
                        <button className="button-close">
                            {/* LOCALIZE: */}
                            <span className="vh">Close</span>
                            <span className="close-icon" onClick={() => {
                                this.setState({ isErrorModalOpen: false, errorText: null });
                                document.getElementsByTagName("html")[0].classList.remove("is-aid-dialog");
                            }}></span>
                        </button>
                    </div>

                    <div className="aid-dialog-content">
                        <div className="aid-dialog-block">
                            <div className="msg">
                                <div className="msg-header txt-center">
                                    <h2 className="msg-title">ERROR</h2>
                                </div>
                                <div className="msg-content">
                                    <p>{this.errorText}</p>
                                </div>
                                <div className="msg-button-holder">
                                    <button className="c-button c-button-primary" onClick={() => {
                                        this.setState({ isErrorModalOpen: false, errorText: null });
                                        document.getElementsByTagName("html")[0].classList.remove("is-aid-dialog");
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>);
    }
}
